<template>
    <div id="signin" class="kt-grid kt-grid--ver kt-grid--root">
        <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                <!--begin::Aside-->
                <app-welcome />
                <!--begin::Aside-->

                <!--begin::Content-->
                <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
                    <!--begin::Body-->
                    <div class="kt-login__body">
                        <!--begin::Signin-->
                        <div class="kt-login__form">
                            <div class="kt-login__title">
                                <h3>{{ $t("user_signin") }}</h3>
                            </div>

                            <!--begin::Form-->
                            <form class="kt-form" action="" novalidate="novalidate" @submit.prevent="onSubmit">
                                <div class="form-group validated">
                                    <input class="form-control m-input" @input="$v.email.$touch()" v-model="email" id="emailInput" type="text" :placeholder="$t('user_email')" name="email" autocomplete="off" />
                                    <div class="invalid-feedback" v-if="!$v.email.email">
                                        {{ $t("error_pleaseEnterValidEmail") }}
                                    </div>
                                </div>

                                <div class="form-group validated">
                                    <input autocomplete="current-password" class="form-control m-input m-login__form-input--last" @input="$v.password.$touch()" v-model="password" id="passwordInput" type="password" :placeholder="$t('user_password')" name="password" />
                                    <div class="invalid-feedback" v-if="!$v.password.strongComplexity">
                                        {{ $t("user_password_rule") }}
                                    </div>
                                </div>

                                <!--begin::Action-->
                                <div class="kt-login__actions">
                                    <a @click="onClickResetPassword" class="cursorPointer kt-link kt-login__link-forgot">
                                        {{ $t("user_forgetPassword") }}
                                    </a>
                                    <button id="signinButton" class="btn btn-info btn-elevate kt-login__btn-primary" :disabled="$v.$invalid">
                                        {{ $t("user_signin") }}
                                    </button>
                                </div>
                                <!--end::Action-->
                            </form>
                            <!--end::Form-->

                        </div>
                        <!--end::Signin-->
                    </div>
                    <!--end::Body-->

                    <!--begin::Head-->
                    <div class="kt-login__head">
                        <span class="kt-login__signup-label">{{ $t("user_noAccountYet") }}</span> <a @click="onClickSignup" class="cursorPointer kt-link kt-login__signup-link">{{ $t("user_signUpWithEmail") }}</a>
                    </div>
                    <!--end::Head-->

                </div>
                <!--end::Content-->
            </div>
        </div>
    </div>
</template>

<script>
import authHelper from "../../helpers/authHelper";
import browserDetectionHelper from "../../helpers/browserDetectionHelper";

import { required, email, helpers } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            email: "",
            password: ""
        };
    },
    created: function() {
        console.log("Component(Signin)::created() - called");

        // If url contains an activation token, we have to verify the email (signup process)
        let activationToken = this.$route.query.confirmuid;

        if (activationToken) {
            this.$router.replace({ query: null });
            this.verifyUser(activationToken);
        }
    },
    mounted: function() {
        console.log("Component(Signin)::mounted() - called");
        KTLayout.init();

        // Check compatibility of browser
        if (browserDetectionHelper.isCurrentSupportedVersion()) {
            $("#browserDetectionModal").modal("hide");
        } else {
            $("#browserDetectionModal").modal("show");
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required,
            strongComplexity: helpers.regex("alpha", /^(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])(?=.*[0-9])(?=.*[a-z]).{8,}$/)
        }
    },
    methods: {
        ...mapActions(["login", "verifyUser", "showUserAlreadyLogged"]),

        onClickSignup() {
            console.log("Component(Signin)::onClickSignup() - called");

            // User already logged in browser (another tab) signup is forbidden
            if (authHelper.isLocalStorageAuthenticated()) {
                this.showUserAlreadyLogged();
                return;
            }

            this.$router.push({ name: "signup" });
        }, 

        // onClickOpenSelectRegion() {
        //     console.log("Component(Signin)::onClickOpenSelectRegion() - called");
        //     $("#switchRegionModal").modal("show");
        // },

        onClickResetPassword() {
            console.log("Component(Signin)::onClickResetPassword() - called");

            // User already logged in browser (another tab) signup is forbidden
            if (authHelper.isLocalStorageAuthenticated()) {
                this.showUserAlreadyLogged();
                return;
            }

            this.$router.push({ name: "forgot" });
        },

        onSubmit() {
            console.log("Component(Signin)::onSubmit() - called");

            // User already logged in browser (another tab) signup is forbidden
            if (authHelper.isLocalStorageAuthenticated()) {
                this.showUserAlreadyLogged();
                return;
            }

            const formData = {
                email: this.email,
                password: this.password
            };
            this.login({ email: formData.email, password: formData.password });
        }
    }
};
</script>

<style scoped>
.logo_ale {
    width: 170px;
}
.cursorPointer {
    cursor: pointer;
}
</style>
